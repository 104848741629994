<section class="main-section">
  <article class="mt-lg-5">
    <div *ngIf="contactForm.show">
      <h2 class="mb-lg-5">{{contact.title}}</h2>
      <p class="mb-lg-5 mb-3 ">
        <a href="mailto:{{contact.email}}" class="text-gradient">
          <i class="fa fa-envelope-o mx-3"></i>
          {{contact.email}}
        </a>
      </p>
      <p class="mb-lg-5 mb-3">
        <a href="tel:{{contact.phone}}" class="text-gradient">
          <i class="fa fa-phone mx-3"></i>
          {{contact.phone}}
        </a>
      </p>


    </div>
    <div *ngIf="contactForm.show">
      <h2 class="mb-lg-5 mb-3">{{contactForm.title}}</h2>
      <form
        [action]="contactForm.formspree"
        method="POST">
        <fieldset>
          <div class="col-lg-6">
            <label for="inputName">{{contactForm.name}}</label>
            <input
              id="inputName"
              class="form-control"
              type="name"
              [name]="contactForm.name"
              [placeholder]="contactForm.namePlaceholder"
              required>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="pe-lg-2">
                <label for="inputEmail">{{contactForm.email}}</label>
                <input
                  id="inputEmail"
                  class="form-control"
                  type="email"
                  [name]="contactForm.email"
                  [placeholder]="contactForm.emailPlaceholder"
                  required>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="ps-lg-2">
                <label for="inputTelephone">{{contactForm.phone}}</label>
                <input
                  id="inputTelephone"
                  class="form-control"
                  type="tel"
                  [name]="contactForm.phone"
                  [placeholder]="contactForm.phonePlaceholder"
                  required>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <label for="messageTextArea">{{contactForm.message}}</label>
            <textarea
              id="messageTextArea"
              class="form-control"
              rows="3"
              [name]="contactForm.message"
              [placeholder]="contactForm.messagePlaceholder"
              required></textarea>
          </div>
          <button
            type="submit"
            class="btn btn-primary mt-2">
            {{contactForm.sendButton}}
          </button>
        </fieldset>
      </form>
    </div>
  </article>
</section>
