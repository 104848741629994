<nav class="navbar navbar-brand navbar-expand">
  <ul class="navbar-nav mr-auto center-div w-100 gap-lg-5 gap-md-4">
    <li class="nav-item">
      <a class="nav-link"
         routerLinkActive="active"
         routerLink="/home">
        HOME
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link"
         routerLinkActive="active"
         routerLink="/projects">
        PROJECTS
      </a>
    </li>
    <li class="nav-item d-none d-lg-block d-md-block">
      <a class="nav-link"
         routerLinkActive="active"
         routerLink="/resume">
        RESUME
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link"
         routerLinkActive="active"
         routerLink="/contact">
        CONTACT
      </a>
    </li>
  </ul>
</nav>
