<section class="main-section">
  <article class="mt-lg-5">
    <div class="center-div">
      <h1><b>
        <span class="text-primary text-gradient">Projects</span>
      </b></h1>
    </div>
    <div class="row">
      <ng-container *ngFor="let item of profile.projects">
        <div class="col-12 col-lg-6">
          <div class="mb-lg-5">
            <app-project-card [item]="item"></app-project-card>
          </div>
        </div>
      </ng-container>
    </div>
  </article>
</section>
