<link rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css">
<section class="footer center-div flex-column">
  <div class="d-flex">
    <ng-container *ngFor="let item of contact.items">
      <a class="footer-icon"
         href="{{item.link}}"
         target="_blank"
         title="{{item.name}}"
         itemprop="significantLink">
        <i class="fa fa-{{item.img}}"></i>
      </a>
    </ng-container>
  </div>
  <span>© {{year}} Copyright: Gabriel Mejía</span>
</section>
