<div [style.display]="'flex'" [style.flex-direction]="'column'">
  <header class="sticky-top bg-white">
    <app-navbar></app-navbar>
  </header>
  <section class="container">
    <router-outlet></router-outlet>
  </section>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>
